define("discourse/plugins/flexible-rate-limits/discourse/controllers/admin-plugins-flexible-rate-limits", ["exports", "discourse/lib/show-modal", "discourse/lib/ajax", "@ember/controller", "@ember/service", "@ember/object", "discourse/plugins/flexible-rate-limits/discourse/components/edit-category-group"], function (_exports, _showModal, _ajax, _controller, _service, _object, _editCategoryGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let _class = _exports.default = (_class2 = class _class2 extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
      _defineProperty(this, "availableCategoryIds", []);
    }
    addCategoryGroup() {
      this.set("model.category_group", null);
      this.modal.show(_editCategoryGroup.default, {
        model: this.get("model")
      });
    }
    editCategoryGroup(categoryGroup) {
      this.set("model.category_group", categoryGroup);
      this.modal.show(_editCategoryGroup.default, {
        model: this.get("model")
      });
    }
    deleteCategoryGroup(categoryGroup) {
      this.get("model.category_groups").removeObject(categoryGroup);
      if (categoryGroup.categories) this.get("availableCategoryIds").removeObjects(categoryGroup.categories);
    }
    saveCategoryGroups() {
      if (!this.get("model.category_groups")) return;
      this.save();
    }
    save() {
      this.set("disableSave", true);
      const categoryGroups = JSON.stringify({
        category_groups: this.get("model.category_groups")
      });
      const args = {
        type: "POST",
        dataType: "json",
        contentType: "application/json",
        data: categoryGroups
      };
      (0, _ajax.ajax)("/admin/plugins/flexible-rate-limits/save.json", args).then(() => this.send("reload")).finally(() => this.set("disableSave", false));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "addCategoryGroup", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "addCategoryGroup"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "editCategoryGroup", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "editCategoryGroup"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "deleteCategoryGroup", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "deleteCategoryGroup"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "saveCategoryGroups", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "saveCategoryGroups"), _class2.prototype)), _class2);
});