define("discourse/plugins/flexible-rate-limits/discourse/components/frl-category-group", ["exports", "discourse-common/utils/decorators", "@ember/component", "@ember/service", "@ember/object", "discourse/plugins/flexible-rate-limits/discourse/components/frl-edit-group"], function (_exports, _decorators, _component, _service, _object, _frlEditGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("selectedCategoryId"), (_obj = {
    classNames: ["frl-category-group"],
    modal: (0, _service.inject)(),
    edit() {
      this.editCategoryGroup(this.get("categoryGroup"));
    },
    delete() {
      this.deleteCategoryGroup(this.get("categoryGroup"));
    },
    addGroup() {
      if (!this.get("categoryGroup.groups")) {
        this.set("categoryGroup.groups", []);
      }
      this.modal.show(_frlEditGroup.default, {
        model: {
          groups: this.get("groups"),
          currentGroups: this.get("categoryGroup.groups")
        }
      });
    },
    editGroup(group) {
      this.modal.show(_frlEditGroup.default, {
        model: {
          groups: this.get("groups"),
          currentGroups: this.get("categoryGroup.groups"),
          group: group
        }
      });
    },
    removeGroup(group) {
      this.get("categoryGroup.groups").removeObject(group);
    },
    addCategory() {
      const categoryId = this.get("formatedCategoryId");
      if (isNaN(categoryId)) return;
      if (!this.get("categoryGroup.categories")) this.set("categoryGroup.categories", []);
      this.get("categoryGroup.categories").addObject(categoryId);
      this.get("availableCategoryIds").removeObject(categoryId);
      this.set("selectedCategoryId", null);
    },
    deleteCategory(categoryId) {
      this.get("categoryGroup.categories").removeObject(categoryId);
      this.get("availableCategoryIds").addObject(categoryId);
    },
    _closeModal() {
      this.closeModal();
    },
    formatedCategoryId(categoryId) {
      return parseInt(categoryId);
    }
  }, (_applyDecoratedDescriptor(_obj, "edit", [_object.action], Object.getOwnPropertyDescriptor(_obj, "edit"), _obj), _applyDecoratedDescriptor(_obj, "delete", [_object.action], Object.getOwnPropertyDescriptor(_obj, "delete"), _obj), _applyDecoratedDescriptor(_obj, "addGroup", [_object.action], Object.getOwnPropertyDescriptor(_obj, "addGroup"), _obj), _applyDecoratedDescriptor(_obj, "editGroup", [_object.action], Object.getOwnPropertyDescriptor(_obj, "editGroup"), _obj), _applyDecoratedDescriptor(_obj, "removeGroup", [_object.action], Object.getOwnPropertyDescriptor(_obj, "removeGroup"), _obj), _applyDecoratedDescriptor(_obj, "addCategory", [_object.action], Object.getOwnPropertyDescriptor(_obj, "addCategory"), _obj), _applyDecoratedDescriptor(_obj, "deleteCategory", [_object.action], Object.getOwnPropertyDescriptor(_obj, "deleteCategory"), _obj), _applyDecoratedDescriptor(_obj, "_closeModal", [_object.action], Object.getOwnPropertyDescriptor(_obj, "_closeModal"), _obj), _applyDecoratedDescriptor(_obj, "formatedCategoryId", [_dec], Object.getOwnPropertyDescriptor(_obj, "formatedCategoryId"), _obj)), _obj)));
});