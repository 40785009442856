define("discourse/plugins/flexible-rate-limits/discourse/components/frl-edit-group-modal", ["exports", "discourse-common/utils/decorators", "@ember/component", "@ember/object"], function (_exports, _decorators, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.on)("didReceiveAttrs"), _dec2 = (0, _decorators.default)("selectedGroupId"), _dec3 = (0, _decorators.default)("topicLimit"), _dec4 = (0, _decorators.default)("postLimit"), _dec5 = (0, _decorators.default)("cooldown"), _dec6 = (0, _decorators.default)("formatedGroupId", "formatedTopicLimit", "formatedPostLimit", "formatedCooldown"), _dec7 = (0, _decorators.default)("formatedGroupId", "formatedTopicLimit", "formatedPostLimit", "formatedCooldown"), _dec8 = (0, _decorators.default)("groups", "currentGroups"), (_obj = {
    _setup() {
      this.set("selectedGroupId", this.get("group.id"));
      this.set("topicLimit", this.get("group.topic_limit"));
      this.set("postLimit", this.get("group.post_limit"));
      this.set("cooldown", this.get("group.cooldown"));
    },
    actions: {
      save() {
        if (this.get("disabled")) return;
        const group = this.get("_group");
        if (this.get("group")) {
          this.get("group").setProperties(group);
        } else {
          this.get("currentGroups").addObject(_object.default.create(group));
        }
        this.closeModal();
      }
    },
    formatedGroupId(selectedGroupId) {
      return parseInt(selectedGroupId);
    },
    formatedTopicLimit(topicLimit) {
      return parseInt(topicLimit);
    },
    formatedPostLimit(postLimit) {
      return parseInt(postLimit);
    },
    formatedCooldown(cooldown) {
      return parseInt(cooldown);
    },
    disabled(groupId, topicLimit, postLimit, cooldown) {
      return isNaN(groupId) || groupId < 1 || isNaN(topicLimit) || topicLimit < 1 || isNaN(postLimit) || postLimit < 1 || isNaN(cooldown) || cooldown < 1;
    },
    _group(groupId, topicLimit, postLimit, cooldown) {
      return {
        id: groupId,
        topic_limit: topicLimit,
        post_limit: postLimit,
        cooldown: cooldown
      };
    },
    availableGroups(groups, currentGroups) {
      const groupIds = (currentGroups || []).map(group => group.id);
      return groups.filter(group => {
        return !groupIds.includes(group.id);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "_setup", [_dec], Object.getOwnPropertyDescriptor(_obj, "_setup"), _obj), _applyDecoratedDescriptor(_obj, "formatedGroupId", [_dec2], Object.getOwnPropertyDescriptor(_obj, "formatedGroupId"), _obj), _applyDecoratedDescriptor(_obj, "formatedTopicLimit", [_dec3], Object.getOwnPropertyDescriptor(_obj, "formatedTopicLimit"), _obj), _applyDecoratedDescriptor(_obj, "formatedPostLimit", [_dec4], Object.getOwnPropertyDescriptor(_obj, "formatedPostLimit"), _obj), _applyDecoratedDescriptor(_obj, "formatedCooldown", [_dec5], Object.getOwnPropertyDescriptor(_obj, "formatedCooldown"), _obj), _applyDecoratedDescriptor(_obj, "disabled", [_dec6], Object.getOwnPropertyDescriptor(_obj, "disabled"), _obj), _applyDecoratedDescriptor(_obj, "_group", [_dec7], Object.getOwnPropertyDescriptor(_obj, "_group"), _obj), _applyDecoratedDescriptor(_obj, "availableGroups", [_dec8], Object.getOwnPropertyDescriptor(_obj, "availableGroups"), _obj)), _obj)));
});