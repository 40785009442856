define("discourse/plugins/flexible-rate-limits/discourse/helpers/flexible-rate-limits-helper", ["exports", "discourse-common/lib/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helpers.registerHelper)("eq", function (params) {
    return params[0] == params[1];
  });
  (0, _helpers.registerHelper)("frlGroupName", function (params) {
    const groups = params[0];
    const group = params[0].findBy("id", params[1]);
    if (!group) return;
    return group.full_name || group.name;
  });
  (0, _helpers.registerHelper)("frlGetCategory", function (params) {
    const categories = Discourse.__container__.lookup("site:main").get("categories");
    return categories.findBy("id", params[0]);
  });
});