define("discourse/plugins/flexible-rate-limits/discourse/components/edit-category-group-modal", ["exports", "discourse-common/utils/decorators", "@ember/component", "@ember/object", "@ember/utils"], function (_exports, _decorators, _component, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.on)("didReceiveAttrs"), _dec2 = (0, _decorators.default)("formatedCategoryGroupName", "formatedTopicLimit", "formatedPostLimit", "formatedCooldown"), _dec3 = (0, _decorators.default)("categoryGroupName"), _dec4 = (0, _decorators.default)("topicLimit"), _dec5 = (0, _decorators.default)("postLimit"), _dec6 = (0, _decorators.default)("cooldown"), _dec7 = (0, _decorators.default)("model.category_groups"), _dec8 = (0, _decorators.default)("formatedCategoryGroupName", "formatedTopicLimit", "formatedPostLimit", "formatedCooldown"), (_obj = {
    _setup() {
      this.set("categoryGroupName", this.get("model.category_group.name"));
      this.set("topicLimit", this.get("model.category_group.topic_limit"));
      this.set("postLimit", this.get("model.category_group.post_limit"));
      this.set("cooldown", this.get("model.category_group.cooldown"));
    },
    actions: {
      save() {
        this.set("errorMsg", null);
        if (this.get("disabled")) return;
        const categoryGroupName = this.get("formatedCategoryGroupName");
        if (categoryGroupName == this.get("model.category_group.name")) {
          this.get("model.category_group").setProperties(this.get("categoryGroup"));
          this._closeModal();
          return;
        }
        if (this.get("categoryGroupNames").includes(categoryGroupName)) {
          this.set("errorMsg", I18n.t("flexible_rate_limits.admin.error.duplicate_category_group_name"));
          return;
        }
        const topicLimit = this.get("formatedTopicLimit");
        const postLimit = this.get("formatedPostLimit");
        if (this.get("model.category_group")) {
          this.get("model.category_group").setProperties(this.get("categoryGroup"));
        } else {
          this.get("model.category_groups").addObject(_object.default.create(this.get("categoryGroup")));
        }
        this._closeModal();
      }
    },
    disabled(categoryGroupName, topicLimit, postLimit, cooldown) {
      return (0, _utils.isEmpty)(categoryGroupName) || isNaN(topicLimit) || topicLimit < 1 || isNaN(postLimit) || postLimit < 1 || isNaN(cooldown) || cooldown < 1;
    },
    formatedCategoryGroupName(categoryGroupName) {
      return (categoryGroupName || "").toLowerCase().trim();
    },
    formatedTopicLimit(topicLimit) {
      return parseInt(topicLimit);
    },
    formatedPostLimit(postLimit) {
      return parseInt(postLimit);
    },
    formatedCooldown(cooldown) {
      return parseInt(cooldown);
    },
    categoryGroupNames(categoryGroups) {
      if (!categoryGroups) return [];
      return categoryGroups.map(cg => {
        return cg.name;
      });
    },
    categoryGroup(categoryGroupName, topicLimit, postLimit, cooldown) {
      return {
        name: categoryGroupName,
        topic_limit: topicLimit,
        post_limit: postLimit,
        cooldown: cooldown
      };
    },
    _closeModal() {
      this.closeModal();
    }
  }, (_applyDecoratedDescriptor(_obj, "_setup", [_dec], Object.getOwnPropertyDescriptor(_obj, "_setup"), _obj), _applyDecoratedDescriptor(_obj, "disabled", [_dec2], Object.getOwnPropertyDescriptor(_obj, "disabled"), _obj), _applyDecoratedDescriptor(_obj, "formatedCategoryGroupName", [_dec3], Object.getOwnPropertyDescriptor(_obj, "formatedCategoryGroupName"), _obj), _applyDecoratedDescriptor(_obj, "formatedTopicLimit", [_dec4], Object.getOwnPropertyDescriptor(_obj, "formatedTopicLimit"), _obj), _applyDecoratedDescriptor(_obj, "formatedPostLimit", [_dec5], Object.getOwnPropertyDescriptor(_obj, "formatedPostLimit"), _obj), _applyDecoratedDescriptor(_obj, "formatedCooldown", [_dec6], Object.getOwnPropertyDescriptor(_obj, "formatedCooldown"), _obj), _applyDecoratedDescriptor(_obj, "categoryGroupNames", [_dec7], Object.getOwnPropertyDescriptor(_obj, "categoryGroupNames"), _obj), _applyDecoratedDescriptor(_obj, "categoryGroup", [_dec8], Object.getOwnPropertyDescriptor(_obj, "categoryGroup"), _obj)), _obj)));
});