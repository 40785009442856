define("discourse/plugins/flexible-rate-limits/discourse/components/frl-composer-info", ["exports", "discourse/models/composer", "discourse-common/utils/decorators", "discourse/lib/ajax", "@ember/component", "@ember/runloop", "@ember/service", "@ember/object", "discourse/plugins/flexible-rate-limits/discourse/components/frl-debug-modal"], function (_exports, _composer, _decorators, _ajax, _component, _runloop, _service, _object, _frlDebugModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("rawData", "limitType"), _dec2 = (0, _decorators.default)("siteSettings.flexible_rate_limits_debug"), _dec3 = (0, _decorators.default)("model.action"), _dec4 = (0, _decorators.observes)("model.categoryId", "model.action", "model.privateMessage"), _dec5 = (0, _decorators.observes)("rawData"), _dec6 = (0, _decorators.default)("data.wait", "showCounter"), _dec7 = (0, _decorators.default)("data.remaining", "data.wait"), (_obj = {
    classNames: ["frl-composer-info"],
    rawData: null,
    didInsertElement() {
      this.setupFetcher();
    },
    data(rawData, limitType) {
      if (!rawData || !limitType) return;
      return rawData[limitType];
    },
    debugEnabled(enabled) {
      return enabled;
    },
    limitType(action) {
      return action === _composer.default.CREATE_TOPIC ? "topic" : action === _composer.default.REPLY ? "post" : null;
    },
    _setupFetcher() {
      if (this.get("model.privateMessage")) return;
      this.set("rawData", null);
      this.setupFetcher();
    },
    _addClass() {
      if (this.get("rawData")) {
        $(".wmd-controls").addClass("with-frl-composer-info");
      } else {
        $(".wmd-controls").removeClass("with-frl-composer-info");
      }
    },
    setupFetcher() {
      this.cancelRunner();
      const fetcher = (0, _runloop.scheduleOnce)("afterRender", () => {
        this.fetchData();
      });
      this.set("fetcher", fetcher);
    },
    fetchData() {
      const categoryId = this.get("model.categoryId") || this.site.get("uncategorized_category_id");
      const limitType = this.get("limitType");
      if (limitType) {
        (0, _ajax.ajax)(`/flexible_rate_limits/${categoryId}/${limitType}.json`, {
          type: "POST"
        }).then(result => this.set("rawData", result)).catch(e => {
          console.error(e);
          this.set("rawData", null);
        }).finally(() => this.countDown());
      }
    },
    countDown() {
      if (!this.get("showCounter")) return;
      const path = `rawData.${this.get("limitType")}.wait`;
      const counter = (0, _runloop.later)(this, () => {
        this.set(path, this.get(path) - 1);
        this.updateModalClock();
        this.countDown();
      }, 1000);
      this.set("counter", counter);
    },
    updateModalClock() {
      const modal = this.get("modal");
      if (modal) {
        modal.set("model.data.wait", this.get("data.wait"));
        modal.set("model.showCounter", this.get("showCounter"));
      }
    },
    formatedCounter(wait, showCounter) {
      if (wait >= 3600) return `${parseInt(wait / 3600)}h`;
      if (wait >= 60) return `${parseInt(wait / 60)}m`;
      return wait + "s";
    },
    showCounter(remaining, wait) {
      return typeof remaining == "number" && remaining < 1 && wait > 0;
    },
    willDestroyElement() {
      $(".wmd-controls").removeClass("with-frl-composer-info");
      this.cancelRunner();
    },
    cancelRunner() {
      ["fetcher", "counter"].forEach(i => {
        if (this.get(i)) (0, _runloop.cancel)(this.get(i));
      });
    },
    showDebugModal() {
      if (!this.siteSettings.flexible_rate_limits_debug) return;
      const model = {
        limitType: this.get("limitType"),
        data: this.get("data"),
        newUser: this.get("rawData.new_user"),
        showCounter: this.get("showCounter")
      };

      // this.modal.show(DebugModal, { model });
    }
  }, (_applyDecoratedDescriptor(_obj, "data", [_dec], Object.getOwnPropertyDescriptor(_obj, "data"), _obj), _applyDecoratedDescriptor(_obj, "debugEnabled", [_dec2], Object.getOwnPropertyDescriptor(_obj, "debugEnabled"), _obj), _applyDecoratedDescriptor(_obj, "limitType", [_dec3], Object.getOwnPropertyDescriptor(_obj, "limitType"), _obj), _applyDecoratedDescriptor(_obj, "_setupFetcher", [_dec4], Object.getOwnPropertyDescriptor(_obj, "_setupFetcher"), _obj), _applyDecoratedDescriptor(_obj, "_addClass", [_dec5], Object.getOwnPropertyDescriptor(_obj, "_addClass"), _obj), _applyDecoratedDescriptor(_obj, "formatedCounter", [_dec6], Object.getOwnPropertyDescriptor(_obj, "formatedCounter"), _obj), _applyDecoratedDescriptor(_obj, "showCounter", [_dec7], Object.getOwnPropertyDescriptor(_obj, "showCounter"), _obj), _applyDecoratedDescriptor(_obj, "showDebugModal", [_object.action], Object.getOwnPropertyDescriptor(_obj, "showDebugModal"), _obj)), _obj)));
});